import React, { ReactElement } from 'react'
import AfterSalesComponent from '../../components/AfterSales/AfterSales'
import defaultImg from '../../assets/images/Property-Page/ps5.webp'
import SEO from '../../components/SEO/SEO'
import { AfterSalesData } from '../../shared/types'

type AfterSalesProps = {
  pageContext: {
    data: AfterSalesData
  }
}

const AfterSales = ({ pageContext: { data } }: AfterSalesProps): ReactElement => (
  <div>
    <SEO
      title="After Sales - Ayala Land Premier"
      description="Welcome to the After Sales page of Ayala Land Premier. Our representatives are online Monday through Friday, from 8AM to 5PM"
      image={data.image ? data.image : defaultImg}
    />
    <h1 style={{ display: 'none' }}>After Sales</h1>
    <AfterSalesComponent id={data.id} image={data.image} content={data.content} />
  </div>
)

export default AfterSales
