import React, { ReactElement } from 'react'
import Div100vh from 'react-div-100vh'
import defaultImg from '../../assets/images/Property-Page/ps5.webp'
import alp_logo from '../../assets/images/brands/alp.webp'
import { AfterSalesData } from '../../shared/types'

import './AfterSales.scss'

const AfterSalesComponent = ({ image, content }: AfterSalesData): ReactElement => (
  <Div100vh className="after-sales-cmp">
    <img src={alp_logo} alt="ALP Logo" className="logo" />
    <div className="row">
      <div
        className="col col--left"
        style={{
          backgroundImage: `url(${image ? image : defaultImg})`
        }}
      />
      <div className="col col--right">
        <h3 className="title">After Sales</h3>
        <p className="sub-title">Customer Support</p>

        <div className="paragraph-content desktop" dangerouslySetInnerHTML={{ __html: content[0].description }} />

        <div className="paragraph-content mobile" dangerouslySetInnerHTML={{ __html: content[1].description }} />
      </div>
    </div>
  </Div100vh>
)

export default AfterSalesComponent
